import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import locationService from '../Services/location.service';
import { location } from '../utils/routingConstants';

const SelectLocation = (props: any) => {
  const { locationId, destRoute } = props;
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (!locationId) {
      const localStorageLocationId = localStorage.getItem('locationId');
      if (localStorageLocationId) {
        history.push(`${location}/${localStorageLocationId}${destRoute}`);
      }
    }
  }, [locationId, destRoute, history]);

  useEffect(() => {
    locationService
      .getAll()
      .then((response: any) => {
        setLocations(response.data);
        const optionSelected = response.data.find((option: any) => option.id === locationId);
        if(locationId){
          localStorage.setItem('locationId', locationId);
        }
        setSelectedLocation(optionSelected);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [locationId]);

  const onChangeHandler = (event: any, data: any) => {
    if (data) {
      history.push(`${location}/${data.id}${destRoute}`);
    }
  };

  return (
    <div className="select-location">
      <Autocomplete disablePortal id="location-select-box" onChange={onChangeHandler} options={locations} value={selectedLocation} getOptionLabel={(option: any) => option.name} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Location" />} />
    </div>
  );
};

export default SelectLocation;
