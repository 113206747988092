import React from 'react';
import Ruler from '@scena/react-ruler';
import './Guides.scss';

const Guides = (props: any) => {
  const { height = 1800, width = 1800 } = props;

  let widthProps = {};

  if (width * 96 > 900) {
    widthProps = {
      width: width * 96,
    };
  } else {
    widthProps = {
      width: 900,
    };
  }
  return (
    <>
      <div className="box"></div>
      <div className="ruler horizontal" style={{ minWidth: width * 96 }}>
        <Ruler type="horizontal" height={30} {...widthProps} unit={1} zoom={96} />
      </div>
      <div className="ruler vertical">
        <Ruler type="vertical" width={30} height={height * 96} unit={1} zoom={96} />
      </div>
    </>
  );
};

export default Guides;
