import { Route, Redirect } from 'react-router-dom';
import { defaultRoute } from '../utils/routingConstants';

const PrivateRoute = (props: any) => {
  const { children, isAuthenticated, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: defaultRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
