import React from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import locationService from '../Services/location.service';

const CreateLocationPage = () => {
  const { handleSubmit, register, reset } = useForm();

  const onSubmit = (data: any) => {
    locationService
      .create(data, data.name, data.phoneNumber)
      .then(() => {
        toast('Location Created!!');
        reset({ id: '', name: '', phoneNumber: '' });
      })
      .catch((e) => {
        toast.error("There was an error while updating location list!!");
        console.log(e)
      });
  };

  return (
    <div className="create-location">
      <PageTitle title="Create Location" />
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField id="location-name" label="Location Name" variant="filled" {...register('name')} />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField id="phoneNumber" label="Phone" variant="filled" {...register('phoneNumber')} type="tel" />
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CreateLocationPage;
