import { Autocomplete, Button, FormControl, Grid, IconButton, InputLabel, NativeSelect, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import TableComponent from '../components/TableComponent';
import templateService from '../Services/template.service';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { pdf } from '../utils/routingConstants';
import pdfService from '../Services/pdf.service';
import { ITemplateData } from '../types/template.type';
import { extractNumber, removeNumber } from '../utils/stringUtils';
import { ILocationTagGroup } from '../types/taggroup.type';
import locationService from '../Services/location.service';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const PdfPage = () => {
  const paramsList = useParams<any>();
  const { locationId } = paramsList;
  const { handleSubmit, register, reset, setValue } = useForm();
  const [pdfList, setPDFList] = useState<any[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateData[]>([]);
  const [tagGroups, setTagGroups] = useState<ILocationTagGroup[]>([]);
  const [selectedTagGroup, setSelectedTagGroup] = useState<any>(null);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    locationService
      .getAll()
      .then((response: any) => {
        setLocationList(response.data);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const locationData: any = locationList.find((item: any) => item.id === locationId);
    const groups = locationData ? locationData.tagGroups : [];
    const groupsList = groups.map((tagGroupData: ILocationTagGroup, index: number) => ({
      id: index,
      label: tagGroupData.tagPrefix,
      ...tagGroupData,
    }));
    setTagGroups(groupsList);
    setSelectedTagGroup(null);
    setValue('tags','');
  }, [locationId, locationList, setValue]);

  const getPdfList = useCallback(() => {
    if (locationId) {
      pdfService
        .getPdfList(locationId)
        .then((response) => {
          setPDFList(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error('There was an error while getting templates list!!');
        });
    }
  }, [locationId]);

  const getTemplateList = useCallback(() => {
    if (locationId) {
      templateService
        .getLocationTemplates(locationId)
        .then((response) => {
          setTemplateList(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error('There was an error while getting templates list!!');
        });
    }
  }, [locationId]);

  useEffect(() => {
    getPdfList();
    getTemplateList();
  }, [getPdfList, getTemplateList]);

  const columns = [
    { name: 'templateType', label: 'Template Type', align: 'left' },
    { name: 'templateName', label: 'Template Name', align: 'left' },
    { name: 'status', label: 'Status', align: 'left' },
    {
      name: 'tags',
      label: 'Tags',
      align: 'left',
      options: {
        setCellProps: () => ({ style: { maxWidth: "300px", 'word-break': 'break-all' }}),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => pdfList[rowIndex].tags.toString(),
      },
    },
    {
      name: 'createdAtUtc',
      label: 'Created At',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => moment(pdfList[rowIndex].createdAtLocal).fromNow(),
      },
    },
    {
      name: 'downloadLink',
      label: 'Link',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <IconButton onClick={() => window.open(pdfList[rowIndex].downloadLink)}>
            <FileDownloadIcon />
          </IconButton>
        ),
      },
    },
    {
      name: 'copyLink',
      label: 'Copy',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <IconButton onClick={() => navigator.clipboard.writeText(pdfList[rowIndex].downloadLink)}>
            <ContentCopyIcon />
          </IconButton>
        ),
      },
    },
  ];

  const onSubmit = (data: any) => {
    const { tags } = data;
    if (tags.includes(',')) {
      data.tags = tags.split(',');
    } else if (tags.includes('-')) {
      const tagsRange = tags.split('-');
      const tagsResult = [];
      const limit1 = extractNumber(tagsRange[0]) || 0;
      const limit2 = extractNumber(tagsRange[1]) || 0;
      const literal = removeNumber(tagsRange[0]);
      for (let i: any = limit1; i <= limit2; i++) {
        tagsResult.push(`${literal}${i}`);
      }
      data.tags = tagsResult;
    } else {
      data.tags = [tags];
    }
    const pdfData = { ...data, locationId };
    pdfService
      .generatePdf(pdfData)
      .then(() => {
        reset({ templateId: '', tags: '' });
        getPdfList();
        toast('PDF generation request added!');
      })
      .catch((error) => {
        console.log(error);
        toast.error('There was an error while generating pdf!!');
      });
  };

  const onTagGroupChangeHandler = (event: any, data: any) => {
    const { tags } = data;
    setSelectedTagGroup(data);
    setValue('tags', tags.toString())
  };


  return (
    <div className="pdf-page">
      <PageTitle title="Download Pdf" />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ m: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${pdf}`} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={7} sx={{ p: 1 }}>
            <TableComponent data={pdfList} columns={columns} />
          </Grid>
          <Grid item xs={5} sx={{ p: 1 }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel
                  id="template-label"
                  shrink={true}
                >
                  Template Id
                </InputLabel>
                <NativeSelect id="template-id" variant="outlined" {...register('templateId')}>
                  <option value=""></option>
                  {templateList.map((templateItem) => (
                    <option value={templateItem.id}>{templateItem.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <div className="select-tag-group">
                  <Autocomplete disablePortal id="tag-group-select-box" onChange={onTagGroupChangeHandler} options={tagGroups} value={selectedTagGroup} getOptionLabel={(option: any) => option.tagPrefix} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Tag Group" />} />
                </div>
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="tags"
                  label="Tags"
                  {...register('tags')}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography variant="subtitle1">Add Tags with ',' to add specific tags. E.g. P1,P5.</Typography>
                <Typography variant="subtitle1">OR Define range with '-'. E.g. P1-P5.</Typography>
              </FormControl>
              <Button variant="contained" type="submit" sx={{ m: 1 }}>
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PdfPage;
