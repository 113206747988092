export const getDayName = (dateStr: Date, locale: string | string[] | undefined) => {
  var date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: 'long' });
};

export const extractNumber = (txt: string) => {
  const numb = txt.match(/\d/g);
  return numb && numb.join('');
};

export const removeNumber = (txt: string) => {
  const numb = txt.replace(/\d/g, '');
  return numb;
};

export const convertToInches = (size: any) => {
  return (parseFloat(size) / 96).toFixed(2).toString();
};
export const convertToPX = (size: any) => {
  return Math.round(parseFloat(size)*96);
}
