import { IPdfRequestModel, IPdfStatusModel } from '../types/pdf.type';
import api from './api';
const API_URL = '/api/Pdf';

class PdfDataService {
    getPdfList(locationId: string) {
        return api.get<IPdfStatusModel[]>(`${API_URL}/${locationId}`);
    }
    generatePdf(pdfData: IPdfRequestModel) {
        return api.post<any>(`${API_URL}`, pdfData);
    }
}

export default new PdfDataService();
