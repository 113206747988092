import { useIsAuthenticated } from '@azure/msal-react';
import PageTitle from '../components/PageTitle';

const Home = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <div className="home">
      <PageTitle title="Home" />
      {isAuthenticated ? <span>Welcome to OhWaiter Support Console!</span> : <span>Please SignIn to continue!</span>}
    </div>
  );
};

export default Home;
