import { useEffect, useState } from 'react';
import PageTitle from '../components/PageTitle';
import { toast } from 'react-toastify';
import TableComponent from '../components/TableComponent';
import { ILocationData } from '../types/location.type';
import locationService from '../Services/location.service';

const Locations = () => {
  const [locationList, setLocationList] = useState< ILocationData[] >([]);

  const columns = [
    { name: 'name', label: 'Location Name' },
    { name: 'phone', label: 'Phone' },
  ];

  useEffect(() => {
    locationService
      .getAll()
      .then((response: any) => setLocationList(response.data))
      .catch((error: any) => {
        toast.error('There was an error while getting locations list!!');
        console.log(error);
      });
  }, []);

  return (
    <div className="location-list">
      <PageTitle title="Locations" />
      <TableComponent data={locationList} columns={columns} />
    </div>
  );
};

export default Locations;
