import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import TableComponent from '../components/TableComponent';
import locationService from '../Services/location.service';
import { ILocationTagGroup } from '../types/taggroup.type';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { tag_groups } from '../utils/routingConstants';
import EditTagGroups from '../components/EditTagGroups';
import { DeleteOutlined } from '@mui/icons-material';

const TagGroups = () => {
  const paramsList = useParams<any>();
  const [locationList, setLocationList] = useState([]);
  const [tagGroups, setTagGroups] = useState<ILocationTagGroup[]>([]);
  const { locationId } = paramsList;

  useEffect(() => {
    locationService
      .getAll()
      .then((response: any) => {
        setLocationList(response.data);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
  }, []);

  const updateTagGroups = (data: any) => {
    const groups = [...tagGroups];
    groups.push(data);
    locationService.updateLocationTagGroups(locationId, groups).then(()=>{
      toast('Tag Groups Updated!');
      locationService
      .getAll()
      .then((response: any) => {
        setLocationList(response.data);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
    }).catch(()=>{
        toast.error('There was an error while updating tag groups list!!');
    })
  }

  const deleteTagGroup = (data: ILocationTagGroup) => {
    let groups = [...tagGroups];
    const groupIndex = groups.findIndex(({tagPrefix}: ILocationTagGroup)=>data.tagPrefix === tagPrefix);
    groups.splice(groupIndex, 1);
    locationService.updateLocationTagGroups(locationId, groups).then(()=>{
      toast('Tag Groups Updated!');
      locationService
      .getAll()
      .then((response: any) => {
        setLocationList(response.data);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
    }).catch(()=>{
        toast.error('There was an error while updating tag groups list!!');
    })
  }

  useEffect(() => {
    const locationData: any = locationList.find((item: any) => item.id === locationId);
    const groups = locationData ? locationData.tagGroups : [];
    setTagGroups(groups);
  }, [locationId, locationList]);

  const columns = [
    { name: 'tagPrefix', label: 'Tag Prefix', align: 'left' },
    {
      name: 'showTag',
      label: 'Show Tag',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          if (tagGroups[dataIndex].showTag) {
            return <CheckCircleIcon />;
          }
          return null;
        },
      },
    },
    {
      name: 'Delete',
      label: 'Delete',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => <IconButton onClick={()=>deleteTagGroup(tagGroups[dataIndex])} ><DeleteOutlined /></IconButton>,
      },
    },
  ];

  const expandableRowComponent = (rowData: any, rowMeta: any) => {
    const tagData = tagGroups[rowMeta.rowIndex];
    const { tags } = tagData;

    return (
      <Fragment>
        <tr>
          <td colSpan={4} style={{ padding: '1rem', background: '#f5f5f5' }}>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: '650' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tag name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tags.map((tag: string, index: number) => {
                    return (
                      <TableRow>
                        <TableCell component="th" variant="head" scope="row" key={index}>
                          {tag}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </td>
        </tr>
      </Fragment>
    );
  };

  return (
    <div className="tag-groups">
      <PageTitle title="Tag Groups" />
      <Grid container spacing={2}>
        <Grid  item xs={12} sx={{ m: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${tag_groups}`} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid  item  xs={6} sx={{ p: 1 }}>
            <TableComponent data={tagGroups} columns={columns} expandableRows={true} renderExpandableRow={expandableRowComponent} />
          </Grid>
          <Grid xs={6} sx={{ p: 1 }}>
            <EditTagGroups updateTagGroups={updateTagGroups}/>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TagGroups;
