import { Button, FormControl, Grid, IconButton, InputLabel, NativeSelect, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import TableComponent from '../components/TableComponent';
import templateService from '../Services/template.service';
import { ITemplateData } from '../types/template.type';
import { location, manageTemplate, templates } from '../utils/routingConstants';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const AllTemplates = () => {
  const paramsList = useParams<any>();
  const { locationId } = paramsList;
  const { handleSubmit, register, reset } = useForm();
  const [templateList, setTemplateList] = useState<ITemplateData[]>([]);

  const history = useHistory();

  const getTemplateList = useCallback(() => {
    if (locationId) {
      templateService
        .getLocationTemplates(locationId)
        .then((response) => {
          setTemplateList(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error('There was an error while getting templates list!!');
        });
    }
  }, [locationId]);

  useEffect(() => {
    getTemplateList();
  }, [getTemplateList]);

  const columns = [
    { name: 'name', label: 'name', align: 'left' },
    { name: 'templateType', label: 'Type', align: 'left' },
    {
      name: 'view',
      label: 'View',
      align: 'left',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <IconButton onClick={() => history.push(`${location}/${locationId}${manageTemplate}/${templateList[rowIndex].id}`)}>
            <RemoveRedEyeIcon />
          </IconButton>
        ),
      },
    },
  ];

  const onSubmit = (data: any) => {
    const template = { ...data, printWidth: '4.25', printHight: '6.25', qrWidthHight: '1.2', qrTop: '3.12', qrLeft: '3.12', locationId }
    templateService
      .upsertTemplate(template)
      .then(() => {
        reset({ name: '', templateType: '' });
        getTemplateList();
        toast('Template successfully updated!');
      })
      .catch((error) => {
        console.log(error);
        toast.error('There was an error while updating template details!!');
      });
  };

  return (
    <div className="all-templates">
      <PageTitle title="Create/List Templates" />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ m: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${templates}`} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={7} sx={{ p: 1 }}>
            <TableComponent data={templateList} columns={columns} />
          </Grid>
          <Grid item xs={5} sx={{ p: 1 }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField id="template-name" label="Template Name" variant="filled" {...register('name')} />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="type-select">Template Type</InputLabel>
                <NativeSelect id="templateType" variant="outlined" {...register('templateType')}>
                  <option value="single">Single</option>
                  <option value="double">Double</option>
                  <option value="sticker">Sticker</option>
                </NativeSelect>
              </FormControl>
              <Button variant="contained" type="submit" sx={{ m: 1 }}>
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllTemplates;
