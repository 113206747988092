import { Autocomplete, Button, FormControl, Grid, InputLabel, NativeSelect, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import templateService from '../Services/template.service';
import { ITemplateData } from '../types/template.type';
import { location, manageTemplate } from '../utils/routingConstants';
import qrCode from '../Images/qrCode.png';

import './ManageTemplate.scss';
import { convertToInches, convertToPX } from '../utils/stringUtils';
import Guides from '../components/Guides';

interface dimensions {
  lastX: number;
  lastY: number;
}

const ManageTemplate = (props: any) => {
  const paramsList = useParams<any>();
  const { locationId, templateId } = paramsList;
  const [templateList, setTemplateList] = useState<ITemplateData[]>([]);
  const [templateDetails, setTemplateDetails] = useState<any>({ fileData: null });
  const history = useHistory();

  const getTemplateDetails = useCallback(() => {
    if (locationId && templateId) {
      templateService
        .getTemplate(locationId, templateId)
        .then((response) => {
          setTemplateDetails(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error('There was an error while getting template details!!');
        });
    }else{
      setTemplateDetails(null);
    }
  }, [locationId, templateId]);

  useEffect(() => {
    if(locationId) {
      templateService
        .getLocationTemplates(locationId)
        .then((response) => {
          setTemplateList(response.data);
          if(response.data && response.data.length>0){
            history.push(`${location}/${locationId}${manageTemplate}/${response.data[0].id}`);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('There was an error while getting templates list!!');
        });
    }
  }, [locationId, history]);

  useEffect(() => {
    getTemplateDetails();
  }, [getTemplateDetails, locationId, templateId]);

  const uploadFile = (event: any) => {
    const { fileData } = templateDetails;
    const formData = new FormData();
    formData.append('file', fileData);
    templateService
      .upsertTemplateBG(locationId, templateId, formData)
      .then(() => {
        setTemplateDetails({ ...templateDetails, fileData: null });
        getTemplateDetails();
        toast('Template Background updated!');
      })
      .catch((error) => {
        console.log(error);
        toast.error('There was an error while updating template details!!');
      });
  };

  const onFileChangeHandler = (event: any) => {
    setTemplateDetails({ ...templateDetails, fileData: event.target.files[0] });
  };

  const updateTemplate = () => {
    const template = { ...templateDetails };
    delete template.id;
    delete template.backgroundImageUrl;
    delete template.fileData;
    templateService
      .upsertTemplate(template)
      .then(() => {
        getTemplateDetails();
        toast('Template successfully updated!');
      })
      .catch((error) => {
        console.log(error);
        toast.error('There was an error while updating template details!!');
      });
  };

  const textOnChangeHandler = (event: any) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    if (fieldName) {
      setTemplateDetails({ ...templateDetails, [fieldName]: fieldValue });
    }
  };

  const onChangeHandler = (event: any, data: any) => {
    history.push(`${location}/${locationId}${manageTemplate}/${data.id}`);
  };

  return (
    <div className="qr-code-designer container">
      <PageTitle title="Manage Template" />
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ p: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${manageTemplate}`} />
        </Grid>
        <Grid item xs={6} sx={{ p: 1 }}>
          <div className="select-template">
            <Autocomplete disablePortal id="template-select-box" onChange={onChangeHandler} options={templateList} value={templateDetails} getOptionLabel={(option: any) => option.name} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Template" />} />
          </div>
        </Grid>
        <Grid item xs={10} sx={{ p: 1 }}>
          {(templateDetails && templateDetails.id) && (
            <div className="ruler-container">
              <Guides height={templateDetails.printHight} width={templateDetails.printWidth}/>
              <div className="pdf-container">
                <div className="bg-image-container">
                  <img id="bg-image" style={{ height: `${templateDetails.printHight}in`, width: `${templateDetails.printWidth}in` }} src={templateDetails.backgroundImageUrl || ''} alt="bgImage" />
                  <Rnd
                    onDragStop={(e: any, d: dimensions) => setTemplateDetails({ ...templateDetails, qrTop: convertToInches(d.lastY), qrLeft: convertToInches(d.lastX) })}
                    onResizeStop={(e, direction, ref, delta, position) => setTemplateDetails({ ...templateDetails, qrWidthHight: convertToInches(ref.style.width) })}
                    position={{
                      x: convertToPX(templateDetails.qrLeft),
                      y: convertToPX(templateDetails.qrTop),
                    }}
                    size={{ width: convertToPX(templateDetails.qrWidthHight), height: convertToPX(templateDetails.qrWidthHight) }}
                    lockAspectRatio={true}
                  >
                    <img id="qr-code" src={qrCode} alt="qrCode" />
                  </Rnd>
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={2} sx={{ p: 1 }}>
          <div className="parameters-panel">
            <form autoComplete="off">
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="backgroundImageUrl"
                  label="Background Image"
                  type="file"
                  onChange={onFileChangeHandler}
                  name="backgroundImageUrl"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button variant="contained" onClick={uploadFile}>
                  Upload
                </Button>
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="name"
                  label="Template Name"
                  name="name"
                  value={templateDetails?.name}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={textOnChangeHandler}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="type-select">Template Type</InputLabel>
                <NativeSelect id="templateType" variant="outlined" name="templateType" value={templateDetails?.templateType} onChange={textOnChangeHandler}>
                  <option value="single">Single</option>
                  <option value="double">Double</option>
                  <option value="sticker">Sticker</option>
                </NativeSelect>
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="bgHeight"
                  label="Background Height"
                  name="printHight"
                  value={templateDetails?.printHight || 0}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={textOnChangeHandler}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="printWidth"
                  label="Background Width"
                  name="printWidth"
                  value={templateDetails?.printWidth || 0}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={textOnChangeHandler}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="qrWidthHight"
                  label="QR Width and Height"
                  value={templateDetails?.qrWidthHight || 0}
                  name="qrWidthHight"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="qrLeft"
                  label="QR Position - Left"
                  value={templateDetails?.qrLeft || 0}
                  name="qrLeft"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="qrTop"
                  label="QR Position - Top"
                  value={templateDetails?.qrTop || 0}
                  name="qrTop"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button variant="contained" onClick={updateTemplate}>
                  Save Dimensions
                </Button>
              </FormControl>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageTemplate;
