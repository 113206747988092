import React from 'react';
import Home from '../pages/Home';
import { Route, Switch } from 'react-router';
import PageLayout from '../Layouts/PageLayout';
import { all, create, defaultRoute, location, manageTemplate, pdf, tags, tag_groups, tag_groups_details, templates } from '../utils/routingConstants';
import PrivateRoute from './PrivateRoute';
import Locations from '../pages/LocationPage';
import { useIsAuthenticated } from '@azure/msal-react';
import CreateLocationPage from '../pages/CreateLocationPage';
import Tags from '../pages/Tags';
import TagGroups from '../pages/TagGroups';
import TagGroupDetails from '../pages/TagGroupDetails';
import ManageTemplate from '../pages/ManageTemplate';
import AllTemplates from '../pages/AllTemplates';
import PdfPage from '../pages/PdfPage';

const Routes = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <PageLayout>
      <Switch>
        <Route exact path={defaultRoute} component={Home} />
        <PrivateRoute exact path={`${location}${all}`} isAuthenticated={isAuthenticated}>
          <Locations />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${create}`} isAuthenticated={isAuthenticated}>
          <CreateLocationPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${tag_groups}`} isAuthenticated={isAuthenticated}>
          <TagGroups />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${tag_groups}`} isAuthenticated={isAuthenticated}>
          <TagGroups />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${tag_groups_details}`} isAuthenticated={isAuthenticated}>
          <TagGroupDetails />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${tag_groups_details}`} isAuthenticated={isAuthenticated}>
          <TagGroupDetails />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${templates}`} isAuthenticated={isAuthenticated}>
          <AllTemplates />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${templates}`} isAuthenticated={isAuthenticated}>
          <AllTemplates />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${manageTemplate}`} isAuthenticated={isAuthenticated}>
          <ManageTemplate />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${manageTemplate}`} isAuthenticated={isAuthenticated}>
          <ManageTemplate />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${manageTemplate}/:templateId`} isAuthenticated={isAuthenticated}>
          <ManageTemplate />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${pdf}`} isAuthenticated={isAuthenticated}>
          <PdfPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${pdf}`} isAuthenticated={isAuthenticated}>
          <PdfPage />
        </PrivateRoute>
      </Switch>
    </PageLayout>
  );
};

export default Routes;
