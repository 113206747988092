import React, { useEffect } from 'react';
import { Button, Checkbox, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { extractNumber, removeNumber } from '../utils/stringUtils';

const EditTagGroups = (props: any) => {
  const { updateTagGroups, disableTags = false, data } = props;
  const { handleSubmit, register, setValue, control, reset } = useForm();

  useEffect(() => {
    if (data) {
      const { tags } = data;
      setValue('tagPrefix', data.tagPrefix);
      setValue('showTag', data.showTag);
      setValue('tags', tags.toString());
    } else {
      reset({ tagPrefix: '', showTag: false, tags: '' });
    }
  }, [data, disableTags, reset, setValue]);

  const onSubmit = (data: any) => {
    const { tags } = data;
    if (tags.includes(',')) {
      data.tags = tags.split(',');
    } else if (tags.includes('-')) {
      const tagsRange = tags.split('-');
      const tagsResult = [];
      const limit1 = extractNumber(tagsRange[0]) || 0;
      const limit2 = extractNumber(tagsRange[1]) || 0;
      const literal = removeNumber(tagsRange[0]);
      for (let i: any = limit1; i <= limit2; i++) {
        tagsResult.push(`${literal}${i}`);
      }
      data.tags = tagsResult;
    } else {
    }
    updateTagGroups(data);
  };

  return (
    <div className="create-tag-group">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="group-prefix"
            label="Tag Prefix"
            {...register('tagPrefix')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="tags"
            label="Tags"
            {...register('tags')}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography variant="subtitle1">Add Tags with ',' to add specific tags. E.g. P1,P5.</Typography>
          <Typography variant="subtitle1">OR Define range with '-'. E.g. P1-P5.</Typography>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row' }}>
          <Controller name="showTag" control={control} render={({ field: props }) => <Checkbox {...props} checked={!!props.value} onChange={(e) => props.onChange(e.target.checked)} />} />
          <FormLabel sx={{ m: 1 }}>Show Tag</FormLabel>
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EditTagGroups;
