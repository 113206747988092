import api from './api';
import { ILocationData } from '../types/location.type';
import { ILocationTagGroup } from '../types/taggroup.type';
const API_URL = '/api/Location';

class LocationDataService {
    getAll() {
        return api.get<ILocationData[]>(API_URL);
    }
    create(data: ILocationData, locationName: string, phoneNumber: string) {
        return api.post<any>(`${API_URL}/${locationName}?phoneNumber=${phoneNumber}`, data);
    }
    
    locationTags(locationId: string) {
        return api.get<ILocationTagGroup>(`${API_URL}/${locationId}/tags`);
    }
    updateLocationTagGroups(locationId: string, data: ILocationTagGroup[]) {
        return api.post<ILocationTagGroup>(`${API_URL}/${locationId}/tag_groups`, data);
    }
}

export default new LocationDataService();
