export const defaultRoute = '/';
export const home = '/home';
export const location = '/location';
export const create = '/create';
export const createLocationRoute = `${location}${create}`;
export const customer = '/customer';
export const createCustomerRoute = `${customer}${create}`;
export const device = '/device';
export const signIn = '/sign-in';
export const about = '/about';
export const pageNotFound = '/page-not-found';
export const all = '/all';
export const buttons = '/buttons';
export const settings = '/settings';
export const tags = '/tags';
export const tag_groups = '/tag-groups';
export const tag_groups_details = '/tag-groups-details';
export const manageTemplate = '/manage-template';
export const templates = '/templates';
export const tagName = '/tag_name';
export const guestNameType = '/guest_name_type';
export const devices = '/devices';
export const response = '/response';
export const status = '/status';
export const notifications = '/notifications';
export const pdf = '/pdf';
