import { ITemplateData, ITemplateUpsertData } from '../types/template.type';
import api from './api';
const API_URL = '/api/Template';

class TemplateDataService {
    getLocationTemplates(locationId: string) {
        return api.get<ITemplateData[]>(`${API_URL}/${locationId}`);
    }
    getTemplate(locationId: string, templateId: string) {
        return api.get<ITemplateData>(`${API_URL}/${locationId}/${templateId}`);
    }
    upsertTemplate(data: ITemplateUpsertData) {
        return api.post<any>(API_URL, data);
    }
    upsertTemplateBG(locationId: string, templateId: string, data: object) {
        return api.post<any>(`${API_URL}/${locationId}/${templateId}/background`, data, {
            headers:{'Content-Type': 'multipart/form-data'}
        });
    }
}

export default new TemplateDataService();
