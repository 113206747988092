import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import TableComponent from '../components/TableComponent';
import locationService from '../Services/location.service';
import { tags } from '../utils/routingConstants';

const Tags = () => {
  const paramsList = useParams<any>();
  const [tagsList, setTagsList] = useState([]);
  const { locationId } = paramsList;

  useEffect(() => {
    locationService
      .locationTags(locationId)
      .then((response: any) => {
        const list = response.data.map((item: any) => ({
          tag: item,
        }));
        setTagsList(list);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
  }, [locationId]);

  const columns = [{ name: 'tag', label: 'Tag Name', align: 'left' }];

  return (
    <div className="create-custom-response">
      <PageTitle title="Create Response" />
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ m: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${tags}`} />
        </Grid>
        <Grid xs={12} sx={{ m: 1 }}>
          <TableComponent data={tagsList} columns={columns} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Tags;
