import React, { Fragment, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocationIcon from '@mui/icons-material/LocationCityOutlined';
import StyleIcon from '@mui/icons-material/Style';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AlignHorizontalLeftOutlinedIcon from '@mui/icons-material/AlignHorizontalLeftOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useHistory } from 'react-router';
import { all, createLocationRoute, defaultRoute, location, manageTemplate, pdf, tag_groups, tag_groups_details, templates } from '../utils/routingConstants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';

const MainListItems = () => {
  const history = useHistory();
  const [openMenus, setOpenMenus] = useState<any>({
    openLocations: true,
    openTemplate: true,
  });

  const toggleOpenMenuHandler = (menuName: string) => {
    const toggleData = openMenus[menuName];
    setOpenMenus({ ...openMenus, [menuName]: !toggleData });
  };

  const gotoLink = (route: string) => history.push(route);

  const { openLocations, openTemplate } = openMenus;

  return (
    <Fragment>
      <ListItemButton onClick={() => gotoLink(defaultRoute)} title="Home">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openLocations')} title="Locations">
        <ListItemIcon>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary="Locations" />
        {openLocations ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openLocations} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${all}`)} title="All Locations">
            <ListItemIcon>
              <AlignHorizontalLeftOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="All Locations" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(createLocationRoute)} title="Create Location">
            <ListItemIcon>
              <AddCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Create Location" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${tag_groups}`)} title="Tag Groups">
            <ListItemIcon>
              <StyleIcon />
            </ListItemIcon>
            <ListItemText primary="Tag Groups" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${tag_groups_details}`)} title="Tag Groups Details">
            <ListItemIcon>
              <ViewStreamIcon />
            </ListItemIcon>
            <ListItemText primary="Tag Groups Details" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openTemplate')} title="Template">
        <ListItemIcon>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary="Template" />
        {openTemplate ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openTemplate} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${templates}`)} title="Create/List Templates">
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Create/List Templates" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${manageTemplate}`)} title="Manage Template">
            <ListItemIcon>
              <HistoryEduIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Template" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => gotoLink(`${location}${pdf}`)} title="Pdf">
        <ListItemIcon>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText primary="Generate Pdf" />
      </ListItemButton>
    </Fragment>
  );
};

export default MainListItems;