import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import TableComponent from '../components/TableComponent';
import locationService from '../Services/location.service';
import { ILocationTagGroup } from '../types/taggroup.type';
import { tag_groups_details } from '../utils/routingConstants';
import EditTagGroups from '../components/EditTagGroups';

const TagGroupDetails = () => {
  const paramsList = useParams<any>();
  const [locationList, setLocationList] = useState([]);
  const [tagGroups, setTagGroups] = useState<ILocationTagGroup[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [selectedTagGroup, setSelectedTagGroup] = useState<any>(null);
  const { locationId } = paramsList;

  useEffect(() => {
    locationService
      .getAll()
      .then((response: any) => {
        setLocationList(response.data);
      })
      .catch((error: any) => {
        toast.error('There was an error while getting tags list!!');
        console.log(error);
      });
  }, []);

  const updateTagGroups = (data: ILocationTagGroup) => {
    const groups = [...tagGroups];
    const groupIndex = groups.findIndex(({ tagPrefix }: ILocationTagGroup) => data.tagPrefix === tagPrefix);
    groups[groupIndex] = data;
    locationService
      .updateLocationTagGroups(locationId, groups)
      .then(() => {
        toast('Tag Groups Updated!');
        locationService
          .getAll()
          .then((response: any) => {
            setLocationList(response.data);
          })
          .catch((error: any) => {
            toast.error('There was an error while getting tags list!!');
            console.log(error);
          });
      })
      .catch(() => {
        toast.error('There was an error while updating tag groups list!!');
      });
  };

  useEffect(() => {
    const locationData: any = locationList.find((item: any) => item.id === locationId);
    const groups = locationData ? locationData.tagGroups : [];
    const groupsList = groups.map((tagGroupData: ILocationTagGroup, index: number) => ({
      id: index,
      label: tagGroupData.tagPrefix,
      ...tagGroupData,
    }));

    let selectedGroup = null;
    let tagsList = [];
    debugger;
    if (groupsList.length > 0) {
      const groupExistingIndex = selectedTagGroup && groupsList.findIndex((groupListItem: any) => groupListItem.tagPrefix === selectedTagGroup.tagPrefix);
      if (groupExistingIndex > -1) {
        if(JSON.stringify(groupsList[groupExistingIndex]) === JSON.stringify(selectedTagGroup)){
          selectedGroup = selectedTagGroup;
        }else{
          selectedGroup = groupsList[groupExistingIndex];
        }
      } else {
        selectedGroup = groupsList[0];
      }
      const { tags } = selectedGroup || { tags: [] };
      tagsList = tags.map((item: any) => ({
        tag: item,
      }));
    }

    setTagGroups(groupsList);
    setSelectedTagGroup(selectedGroup);
    setTagList(tagsList);
  }, [locationId, locationList, selectedTagGroup]);

  const onChangeHandler = (event: any, data: any) => {
    const { tags } = data;
    const tagsList = tags.map((item: any) => ({
      tag: item,
    }));
    setTagList(tagsList);
    setSelectedTagGroup(data);
  };

  const columns = [{ name: 'tag', label: 'Tag Name', align: 'left' }];

  return (
    <div className="tag-group-details">
      <PageTitle title="Tag Group Details" />
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ p: 1 }}>
          <SelectLocation locationId={locationId} destRoute={`${tag_groups_details}`} />
        </Grid>
        <Grid item xs={6} sx={{ p: 1 }}>
          <div className="select-tag-group">
            <Autocomplete disablePortal id="tag-group-select-box" onChange={onChangeHandler} options={tagGroups} value={selectedTagGroup} getOptionLabel={(option: any) => option.tagPrefix} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Tag Group" />} />
          </div>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} sx={{ p: 1 }}>
            <TableComponent data={tagList} columns={columns} />
          </Grid>
          <Grid xs={6} sx={{ p: 1 }}>
            <EditTagGroups updateTagGroups={updateTagGroups} disableTags={true} data={selectedTagGroup} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TagGroupDetails;
